import React, { Component } from 'react';
import AccountLogin from '../components/account/login';
import AccountManager from '../managers/Account';

class Login extends Component {
  constructor(props) {
    super(props);
    this.state = {
      creatorData: {},
    };
  }

  componentDidMount() {
    // TODO: if user is logged in, go to the account/collections page
    if (AccountManager.isLoggedIn()) {
      window.location.href = '/account/collection';
    }
  }

  render() {
    return (
      <div>
        <AccountLogin />
      </div>
    );
  }
}

export default Login;
