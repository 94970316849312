import React, { Component } from 'react';

class CustomFooter extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {}

  render() {
    const { isSticky } = this.state;
    return (
      <div className="footer custom-footer">
        <div className="footer-center">
          <a href="https://discord.com/invite/dZEGbB7G66" target="_blank" rel="noreferrer">
            <img className="icon-link" src="https://ipfs.ethosnft.com/legends/icon-discord.svg" alt="" />
          </a>
          <a href="https://twitter.com/I_Am_NetworkNFT" target="_blank" rel="noreferrer">
            <img className="icon-link" src="https://ipfs.ethosnft.com/legends/icon-twitter.svg" alt="" />
          </a>
          <a href="https://www.instagram.com/networknftstudio/" target="_blank" rel="noreferrer">
            <img className="icon-link" src="https://ipfs.ethosnft.com/legends/icon-instagram.svg" alt="" />
          </a>
        </div>
      </div>
    );
  }
}

export default CustomFooter;
