import React from 'react';
import ReactDOM from 'react-dom';
import {
  BrowserRouter,
  Routes,
  Route,
} from 'react-router-dom';
import * as Sentry from '@sentry/react';
import { BrowserTracing } from '@sentry/tracing';
import Container from './container.jsx';
import Home from './routes/home.jsx';
import CountdownPage from './routes/countdown.jsx';
import CountdownPageAlternateLeft from './routes/countdown-alternate.jsx';
import CountdownPageAlternateRight from './routes/countdown-alternate-right.jsx';
import CountdownPageHHOF from './routes/countdown-hhof.jsx';
import DropPage from './routes/drop.jsx';
import Login from './routes/login.jsx';
import Register from './routes/register.jsx';
import PasswordReset from './routes/reset.jsx';
import Account from './routes/account.jsx';
import AccountDetails from './components/account/details.jsx';
import AccountBilling from './components/account/billing.jsx';
import AccountPassword from './components/account/password.jsx';
import AccountWallet from './components/account/wallet.jsx';
import AccountCollection from './components/account/collection.jsx';
import AccountVerify from './components/account/verify.jsx';
import './sass/styles.scss';

// (async () => {
//   if (process.env.CREATOR_SUBDOMAIN) {
//     await import('./sass/components/' + process.env.CREATOR_SUBDOMAIN + '.scss');
//   }
// })();

Sentry.init({
  dsn: 'https://478b199ebcd7460ea20a47393f72fedc@o1273042.ingest.sentry.io/6467146',
  integrations: [new BrowserTracing()],
  environment: process.env.NODE_ENV,
  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: 1.0,
});

ReactDOM.render(
  <BrowserRouter>
    <Routes>

      <Route path="/" element={<Container />}>
        <Route path="/" element={<Home />} />
        <Route path="drop" element={<DropPage />}>
          <Route path=":dropId" element={<DropPage />} />
        </Route>
        <Route path="login" element={<Login />} />
        <Route path="register" element={<Register />} />
        <Route path="reset" element={<PasswordReset />}>
          <Route path=":tokenId" element={<PasswordReset />} />
        </Route>
        <Route path="account" element={<Account />}>
          <Route path="details" element={<AccountDetails />} />
          <Route path="password" element={<AccountPassword />} />
          <Route path="billing" element={<AccountBilling />} />
          <Route path="wallet" element={<AccountWallet />} />
          <Route path="collection" element={<AccountCollection />} />
          <Route path="verify" element={<AccountVerify />} />
        </Route>
      </Route>
      {process.env.COUNTDOWN_TEMPLATE === 'alternateLeft'
        && <Route path="/countdown" element={<CountdownPageAlternateLeft />} />}
      {process.env.COUNTDOWN_TEMPLATE === 'alternateRight'
        && <Route path="/countdown" element={<CountdownPageAlternateRight />} />}
      {process.env.COUNTDOWN_TEMPLATE === 'hhof'
        && <Route path="/countdown" element={<CountdownPageHHOF />} />}
      {!process.env.COUNTDOWN_TEMPLATE
        && <Route path="/countdown" element={<CountdownPage />} />}

    </Routes>
  </BrowserRouter>,
  document.getElementById('app'),
);
