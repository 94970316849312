import React, { Component } from 'react';
import PropTypes from 'prop-types';

class Throbber extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const { throbberText, theme, throbberType } = this.props;
    return (
      <div className={theme === 'branded' ? 'throbber -branded' : 'throbber'}>
        <div className="throbber-content">
          <p>{throbberText || 'Loading...'}</p>
          {throbberType === 'inline'
            ? (
              <div className="lds-ellipsis">
                <div></div>
                <div></div>
                <div></div>
                <div></div>
              </div>
            )
            : (
              <div className="lds-ripple">
                <div></div>
                <div></div>
              </div>
            )}
        </div>
      </div>
    );
  }
}

Throbber.propTypes = {
  throbberText: PropTypes.string,
  theme: PropTypes.string,
  throbberType: PropTypes.string,
};

export default Throbber;
