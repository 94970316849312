import React, { Component } from 'react';
import { ToastContainer, toast } from 'react-toastify';
import PubSub from 'pubsub-js';
import CookieConsent from 'react-cookie-consent';
import Countdown, { zeroPad } from 'react-countdown';
import moment from 'moment-mini';
import CreatorManager from '../managers/Creator';
import FullPageLoader from '../components/throbber/loader';
import NewsletterSignup from '../components/newsletterSignup';
import SimpleModal from '../components/modal/modal';
import LoginForm from '../components/account/loginForm';
import AccountManager from '../managers/Account';
import CustomTopNav from '../components/navigation/custom-top-nav';
import CustomFooter from '../components/navigation/custom-footer';

class Home extends Component {
  constructor(props) {
    super(props);
    this.state = {
      dropId: process.env.DROP_ID,
      dropData: null,
      isLoading: true,
      countdownData: null,
      friendlyDate: null,
      showLoginModal: false,
      hasPreRegBonus: process.env.PRE_REGISTRATION_BONUS,
    };
  }

  parseCountdown = (date) => {
    console.log('=== parseCountdown ===');
    console.log(date);
    this.setState({
      countdownData: moment(date).unix() * 1000,
      friendlyDate: moment(date).format('ha  on dddd, MMMM D, YYYY'),
    });
  }

  getDropData = async () => {
    const { dropId } = this.state;
    const dropData = await CreatorManager.getDrop(dropId);
    this.parseCountdown(dropData.drop.countDown.countDownDate);

    if (dropData) {
      this.setState({
        isLoading: false,
        dropData,
      });
    }
  }

  setHeroHeight = () => {
    const hero = document.getElementById('countdownContainer');
    const bodyHeight = document.body.scrollHeight;
    const windowHeight = window.innerHeight;
    hero.style.height = windowHeight + 'px';
    // if (hero && (bodyHeight < windowHeight)) {
    //   hero.style.height = window.innerHeight + 'px';
    // }
  }

  fadeIntro = () => {
    const intro = document.getElementById('fader');
    if (intro) {
      intro.classList.add('-fade-in');
    }
  }

  showLoginModal = () => {
    this.setState({
      showModal: false,
      showLoginModal: true,
    });
  }

  loginSuccess = async () => {
    const { hasPreRegBonus } = this.state;
    PubSub.publish('account_updated');
    // hhofPreRegExistingInfo
    if (hasPreRegBonus && localStorage.getItem(process.env.CREATOR_SUBDOMAIN + 'PreRegExistingInfo')) {
      // generate a free NFT token
      const nftToken = await AccountManager.generateFreeNft(AccountManager.getToken(), process.env.PRE_REGISTRATION_DROP_ID);
      if (nftToken && nftToken.token) {
        localStorage.setItem(process.env.CREATOR_SUBDOMAIN + 'FreeToken', nftToken.token);
        localStorage.removeItem(process.env.CREATOR_SUBDOMAIN + 'PreRegExistingInfo');
        toast.success('Log in success.');
        this.setState({
          showLoginModal: false,
        });
        window.location.href = '/account/collection';
      } else {
        localStorage.setItem(process.env.CREATOR_SUBDOMAIN + 'FreeToken', 'already claimed');
        localStorage.removeItem(process.env.CREATOR_SUBDOMAIN + 'PreRegExistingInfo');
        toast.error(nftToken.message);
        this.setState({
          showLoginModal: false,
        });
      }
    }
  }

  loginFailed = (error) => {
    toast.error(error || 'Oops! Something went wrong. Try again later.');
  }

  async componentDidMount() {
    const that = this;
    await this.getDropData();
    setTimeout(() => {
      that.setHeroHeight();
      that.fadeIntro();
    }, 200);
    window.addEventListener('resize', that.setHeroHeight);
  }

  render() {
    const {
      showLoginModal, countdownData, dropData, isLoading,
    } = this.state;
    const renderer = ({
      days, hours, minutes, seconds, completed,
    }) => {
      if (completed) {
        window.location.href = '/';
      }
      // Render a countdown
      return (
        <div className="countdown-timer -top-padding">
          <div className="countdown-timer__item -xlarge">
            <span>days</span>
            <div className="countdown-timer__item--tile">{zeroPad(days)}</div>
          </div>
          <div className="countdown-timer__item -xlarge">
            <span>hours</span>
            <div className="countdown-timer__item--tile">{zeroPad(hours)}</div>
          </div>
          <div className="countdown-timer__item -xlarge">
            <span>mins</span>
            <div className="countdown-timer__item--tile">{zeroPad(minutes)}</div>
          </div>
          <div className="countdown-timer__item -xlarge">
            <span>secs</span>
            <div className="countdown-timer__item--tile">{zeroPad(seconds)}</div>
          </div>
        </div>
      );
    };
    return (
      <div>
        <CustomTopNav />
        <SimpleModal isOpen={showLoginModal} onBeforeClose={() => this.setState({ showLoginModal: false })}>
          <LoginForm
            onSuccess={() => this.loginSuccess()}
            onFail={(error) => this.loginFailed(error)}
            onRegisterButton={() => this.setState({ showLoginModal: false })} />
        </SimpleModal>
        {isLoading
          ? (
            <div>
              <FullPageLoader />
            </div>
          )
          : (
            <div>
              {dropData
                && (
                <div>
                  <ToastContainer
                    position="top-right"
                    autoClose={5000}
                    pauseOnHover />

                  <div
                    id="countdownContainer"
                    className="custom-counter__full-container"
                    style={{
                      backgroundImage: 'url(' + process.env.COUNTDOWN_BACKGROUND + ')',
                    }}>
                    
                    <div className="custom-counter-content">
                      <div id="fader" className="countdown-counter-content__inner">
                        <h1 className="countdown-counter__custom-hdr">
                          OWN A PIECE
                          <br />
                          <span>OF HISTORY</span>
                        </h1>
                        <div className="countdown-counter__divider">
                          PRE-REGISTER AND GET YOUR FREE LEGENDS OF HOCKEY: GREATNESS CALLING NFT
                        </div>
                        <div>
                          <NewsletterSignup
                            onRegisterSuccess={() => this.setHeroHeight()}
                            onLoginRequest={() => this.showLoginModal()} />
                        </div>
                        {/* <img src="https://ipfs.ethosnft.com/hhof/hdr-countdown2.png" alt="" /> */}
                        {/* <div>
                          {countdownData
                          && (
                            <Countdown
                              date={countdownData}
                              renderer={renderer} />
                          )}
                        </div> */}
                      </div>
                    </div>
                    
                  </div>
                  <div className="custom-content-container -secondary">
                    <div className="custom-content__inner -slim">
                      <div className="section-header">
                        <h2>GREATNESS CALLING&nbsp;NFT</h2>
                        <p>
                          <span>Claim your free NFT and </span>
                          get access to the digital companion book to the 10-part tv series
                        </p>
                      </div>
                    </div>
                  </div>

                  <div
                    className="custom-content-container -background"
                    style={{
                      backgroundImage: 'url(' + process.env.COUNTDOWN_BACKGROUND + ')',
                    }}>
                    <div className="custom-content__inner -slim">
                      <div className="section-video">
                        <h2>LEGENDS OF HOCKEY</h2>
                        <h3>GREATNESS CALLING 2000&nbsp;-&nbsp;2020</h3>
                        <p>Hockey Hall of Fame’s 10-part series is premiering on TSN</p>

                        <div className="section-video__player">
                          <video controls controlsList="nodownload" poster="https://ipfs.ethosnft.com/legends/video-cover.png">
                            <source src="https://ipfs.ethosnft.com/legends/legends-trailer.mp4" type="video/mp4" />
                            <track
                                default
                                kind="captions"
                                srcLang="en"
                                src="" />
                          </video>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="custom-content-container -tertiary">
                    <div className="custom-content__inner -slim">
                      <div className="section-countdown">
                        <img className="section-countdown__logo" width="160" src="https://ipfs.ethosnft.com/legends/logo-hhof.png" alt="" />
                        <h3>OFFICIALLY LICENSED</h3>
                        <h2>HOCKEY HALL OF FAME DIGITAL COLLECTIBLES</h2>
                        <div>
                          {countdownData
                          && (
                            <Countdown
                              date={countdownData}
                              renderer={renderer} />
                          )}
                        </div>
                        <div className="section-countdown__coming-soon">
                          COMING SOON
                        </div>
                        <div className="section-countdown__legal">
                          &copy; 2022 LEGENDS, LEGENDS OF HOCKEY and HOCKEY HALL OF FAME are official marks of Hockey Hall of Fame and Museum. All Rights Reserved. © 2022 ethos multiverse inc.
                          {' '}
                        </div>
                      </div>
                    </div>
                  </div>

                  <CookieConsent
                    cookieName={process.env.CREATOR_SUBDOMAIN + 'CookieConsent'}>
                    <a href="https://www.ethosnft.com">ethos</a>
                    {' '}
                    uses cookies to provide necessary website functionality,
                    improve your experience and analyze our traffic. By using our website, you agree to&nbsp;
                    <a target="_blank" href="https://www.ethosnft.com/privacy" rel="noreferrer">our privacy policy</a>
                    {' '}
                    and our cookies usage.
                    
                  </CookieConsent>
                </div>
                )}
            </div>
          )}
        <CustomFooter />
      </div>
    );
  }
}

export default Home;
