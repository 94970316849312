import React, { Component } from 'react';
import { Link } from 'react-router-dom';

class CustomTopNav extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showMobileNav: false,
    };
  }

  componentDidMount() {}

  toggleNav = (e) => {
    e.preventDefault();
    const { showMobileNav } = this.state;
    this.setState({
      showMobileNav: !showMobileNav,
    });
  }

  handleMobileNav = () => {
    this.setState({
      showMobileNav: false,
    });
  }

  render() {
    const { showMobileNav } = this.state;
    return (
      <div id="topNav" className="top-nav -relative">
        <div className="top-nav__inner -slim">
          <img className="network-logo" src="https://ipfs.ethosnft.com/legends/logo-network.png" alt="" />
          <img width="130" className="top-nav__center-logo" src="https://ipfs.ethosnft.com/legends/logo-hhof.png" alt="" />
          <div className="top-nav__ctas--custom">
            <a href="https://discord.com/invite/dZEGbB7G66" target="_blank" rel="noreferrer">
              <img className="icon-link" src="https://ipfs.ethosnft.com/legends/icon-discord.svg" alt="" />
            </a>
            <a href="https://twitter.com/I_Am_NetworkNFT" target="_blank" rel="noreferrer">
              <img className="icon-link" src="https://ipfs.ethosnft.com/legends/icon-twitter.svg" alt="" />
            </a>
            <a href="https://www.instagram.com/networknftstudio/" target="_blank" rel="noreferrer">
              <img className="icon-link" src="https://ipfs.ethosnft.com/legends/icon-instagram.svg" alt="" />
            </a>
            <Link
              className="icon-link__text"
              to="/login">
              Log in
            </Link>
          </div>
        </div>
      </div>
    );
  }
}

export default CustomTopNav;
