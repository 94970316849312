import React, { Component } from 'react';
import { ToastContainer, toast } from 'react-toastify';
import PubSub from 'pubsub-js';
import CookieConsent from 'react-cookie-consent';
import Countdown, { zeroPad } from 'react-countdown';
import moment from 'moment-mini';
import CreatorManager from '../managers/Creator';
import FullPageLoader from '../components/throbber/loader';
import Register from '../components/register';
import SimpleModal from '../components/modal/modal';
import LoginForm from '../components/account/loginForm';
import AccountManager from '../managers/Account';

class Home extends Component {
  constructor(props) {
    super(props);
    this.state = {
      dropId: process.env.DROP_ID,
      dropData: null,
      isLoading: true,
      countdownData: null,
      friendlyDate: null,
      showLoginModal: false,
      hasPreRegBonus: process.env.PRE_REGISTRATION_BONUS,
    };
  }

  parseCountdown = (date) => {
    console.log('=== parseCountdown ===');
    console.log(date);
    this.setState({
      countdownData: moment(date).unix() * 1000,
      friendlyDate: moment(date).format('ha  on dddd, MMMM D, YYYY'),
    });
  }

  getDropData = async () => {
    const { dropId } = this.state;
    const dropData = await CreatorManager.getDrop(dropId);
    this.parseCountdown(dropData.drop.countDown.countDownDate);

    if (dropData) {
      this.setState({
        isLoading: false,
        dropData,
      });
    }
  }

  setHeroHeight = () => {
    const hero = document.getElementById('countdownContainer');
    const bodyHeight = document.body.scrollHeight;
    const windowHeight = window.innerHeight;
    // hero.style.height = windowHeight + 'px';
    if (hero && (bodyHeight < windowHeight)) {
      hero.style.height = window.innerHeight + 'px';
    }
  }

  fadeIntro = () => {
    const intro = document.getElementById('fader');
    if (intro) {
      intro.classList.add('-fade-in');
    }
  }

  showLoginModal = () => {
    this.setState({
      showModal: false,
      showLoginModal: true,
    });
  }

  loginSuccess = async () => {
    const { hasPreRegBonus } = this.state;
    PubSub.publish('account_updated');
    // hhofPreRegExistingInfo
    if (hasPreRegBonus && localStorage.getItem(process.env.CREATOR_SUBDOMAIN + 'PreRegExistingInfo')) {
      // generate a free NFT token
      const nftToken = await AccountManager.generateFreeNft(AccountManager.getToken(), process.env.PRE_REGISTRATION_DROP_ID);
      if (nftToken && nftToken.token) {
        localStorage.setItem(process.env.CREATOR_SUBDOMAIN + 'FreeToken', nftToken.token);
        localStorage.removeItem(process.env.CREATOR_SUBDOMAIN + 'PreRegExistingInfo');
        toast.success('Log in success.');
        this.setState({
          showLoginModal: false,
        });
        window.location.href = '/account/collection';
      } else {
        localStorage.setItem(process.env.CREATOR_SUBDOMAIN + 'FreeToken', 'already claimed');
        localStorage.removeItem(process.env.CREATOR_SUBDOMAIN + 'PreRegExistingInfo');
        toast.error(nftToken.message);
        this.setState({
          showLoginModal: false,
        });
      }
    }
  }

  loginFailed = (error) => {
    toast.error(error || 'Oops! Something went wrong. Try again later.');
  }

  async componentDidMount() {
    const that = this;
    await this.getDropData();
    setTimeout(() => {
      that.setHeroHeight();
      that.fadeIntro();
    }, 200);
    window.addEventListener('resize', that.setHeroHeight);
  }

  render() {
    const {
      showLoginModal, countdownData, dropData, isLoading,
    } = this.state;
    const renderer = ({
      days, hours, minutes, seconds, completed,
    }) => {
      if (completed) {
        window.location.href = '/';
      }
      // Render a countdown
      return (
        <div className="countdown-timer -top-padding">
          <div className="countdown-timer__item -xlarge">
            {zeroPad(days)}
            {' '}
            <span>days</span>
          </div>
          <div className="countdown-timer__item -xlarge">
            {zeroPad(hours)}
            {' '}
            <span>hours</span>
          </div>
          <div className="countdown-timer__item -xlarge">
            {zeroPad(minutes)}
            {' '}
            <span>mins</span>
          </div>
          <div className="countdown-timer__item -xlarge">
            {zeroPad(seconds)}
            {' '}
            <span>secs</span>
          </div>
        </div>
      );
    };
    return (
      <div>
        <SimpleModal isOpen={showLoginModal} onBeforeClose={() => this.setState({ showLoginModal: false })}>
          <LoginForm
            onSuccess={() => this.loginSuccess()}
            onFail={(error) => this.loginFailed(error)}
            onRegisterButton={() => this.setState({ showLoginModal: false })} />
        </SimpleModal>
        {isLoading
          ? (
            <div>
              <FullPageLoader />
            </div>
          )
          : (
            <div>
              {dropData
                && (
                <div>
                  <ToastContainer
                    position="top-right"
                    autoClose={5000}
                    pauseOnHover />

                  <div id="countdownContainer" className="countdown-full-container">
                    
                    <div className="countdown-counter-content -align-left -absolute">
                      <div id="fader" className="countdown-counter-content__inner">
                        <h1 className="countdown-counter__custom-hdr">
                          {process.env.HERO_TITLE}
                        </h1>
                        <div className="countdown-counter__divider">
                          COMING SOON
                        </div>
                        {/* <img src="https://ipfs.ethosnft.com/hhof/hdr-countdown2.png" alt="" /> */}
                        <div>
                          {countdownData
                          && (
                            <Countdown
                              date={countdownData}
                              renderer={renderer} />
                          )}
                        </div>
                      </div>
                    </div>
                    <div
                        className="countdown-form-container -align-right"
                        style={{
                          backgroundImage: 'url(' + process.env.FORM_BACKGROUND + ')',
                        }}>
                      <div id="countdownForm" className="countdown-form-container__inner -no-offset">
                        <img className="countdown-form__logo" src={process.env.LARGE_LOGO} width={process.env.LARGE_LOGO_WIDTH} alt="" />
                        <div className="countdown-form__box">
                          <Register
                            buttonType="block"
                            onRegisterSuccess={() => this.setHeroHeight()}
                            onLoginRequest={() => this.showLoginModal()} />
                        </div>
                        <div className="countdown-form__footer">
                          {process.env.ADDITIONAL_LEGAL_TEXT
                          && (
                          <div>
                            Legends, Legends of Hockey, and Hockey Hall of Fame are official marks of Hockey Hall of Fame and Museum. All Rights Reserved.
                            <br />
                            <br />
                          </div>
                          )}
                          &copy; 2022 ethos multiverse inc.
                        </div>
                      </div>
                    </div>
                  </div>

                  <CookieConsent
                    cookieName={process.env.CREATOR_SUBDOMAIN + 'CookieConsent'}>
                    <a href="https://www.ethosnft.com">ethos</a>
                    {' '}
                    uses cookies to provide necessary website functionality,
                    improve your experience and analyze our traffic. By using our website, you agree to&nbsp;
                    <a target="_blank" href="https://www.ethosnft.com/privacy" rel="noreferrer">our privacy policy</a>
                    {' '}
                    and our cookies usage.
                    
                  </CookieConsent>
                </div>
                )}
            </div>
          )}
      </div>
    );
  }
}

export default Home;
