import React, { Component } from 'react';
import { Outlet } from 'react-router-dom';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import TopNav from 'components/navigation/top-nav';
import Footer from 'components/navigation/footer';
import FullPageLoader from 'components/throbber/loader';
import AccountManager from 'managers/Account';
import SimpleModal from 'components/modal/modal';
import CreatorManager from 'managers/Creator';
import CookieConsent from 'react-cookie-consent';
import moment from 'moment-mini';
import ConfirmTransfer from './components/transfer/confirmTransfer';

class Container extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: true,
      dropDetails: null,
      showContent: false,
      showModal: false,
      mode: '',
      currentDate: moment().unix() * 1000,
    };
  }

  getAccountDetails = async () => {
    await AccountManager.get(AccountManager.getToken());
  }

  cancelTransfer = (transferToken) => {
    const { isFreeTransfer } = this.state;
    if (isFreeTransfer) {
      localStorage.removeItem(process.env.CREATOR_SUBDOMAIN + 'FreeToken');
      localStorage.removeItem(process.env.CREATOR_SUBDOMAIN + 'Countdown');
      this.setState({
        showModal: false,
      });
    } else {
      localStorage.removeItem('NFT_TRANSFER_' + transferToken);
      // remove query string
      window.location.href = window.location.href.split('?')[0];
    }
  }

  transferNFT = async (transferToken, isFree) => {
    const payload = {
      transferToken,
    };
    let verified;
    if (isFree) {
      verified = await AccountManager.transferFreeNFt(AccountManager.getToken(), payload);
    } else {
      verified = await AccountManager.transferNFt(AccountManager.getToken(), payload);
    }
    if (verified && verified.success) {
      if (isFree) {
        localStorage.removeItem(process.env.CREATOR_SUBDOMAIN + 'FreeToken');
        localStorage.setItem(process.env.CREATOR_SUBDOMAIN + 'PreRegTokenClaimed', true);
      } else {
        localStorage.removeItem('NFT_TRANSFER_' + transferToken);
      }
      this.setState({
        showModal: false,
      });
      toast.success(verified.message);
    } else {
      localStorage.removeItem('NFT_TRANSFER_' + transferToken);
      this.setState({
        showModal: false,
      });
      toast.error('Sorry. This transfer is no longer available.');
    }
  }

  findTransferKey = () => {
    let nftToken = null;
    let tokenType = null;
    Object.entries(localStorage).map((obj) => {
      const key = obj[0];
      if (key.includes('NFT_TRANSFER')) {
        nftToken = key;
        tokenType = 'transfer';
      }
      if (key.includes('FreeToken')) {
        if (obj[1] !== 'already claimed') {
          nftToken = key;
          tokenType = 'free';
        }
      }
      return true;
    });
    return {
      token: nftToken,
      tokenType,
    };
  }

  getDropData = async () => {
    const that = this;
    const { currentDate } = this.state;
    const dropData = await CreatorManager.getDrop();
    let countdownDate;
    // Check if dropData.drop has a countdown set. If it doesn, always display the countdown page
    if (dropData.drop.countDown && dropData.drop.countDown.countDownDate) {
      countdownDate = moment(dropData.drop.countDown.countDownDate).unix() * 1000;
    }
    if (countdownDate > currentDate && (!window.location.href.includes('login') && !window.location.href.includes('reset') && !window.location.href.includes('register') && !window.location.href.includes('account'))) {
      console.log('countdown is on!');
      window.location.href = '/countdown';
    } else if (dropData) {
      that.setState({
        dropDetails: dropData,
        isLoading: false,
      });
    }
  }

  componentDidMount = async () => {
    // check if the user is logged in. If they are, grab the account details
    let transferKey = null;
    if (AccountManager.isLoggedIn()) {
      if (this.findTransferKey()) {
        transferKey = this.findTransferKey();
        this.setState({
          transferToken: localStorage.getItem(transferKey.token),
        });
        if (localStorage.getItem(transferKey.token)) {
          this.setState({
            showModal: true,
            mode: 'confirmTransfer',
            isFreeTransfer: transferKey.tokenType === 'free',
          });
          // await this.transferNFT(localStorage.getItem(transferKey));
        }
      }
      await this.getAccountDetails();
    }

    // TODO: Get the drop details now
    await this.getDropData();
    this.setState({
      showContent: true,
    });
  }

  render() {
    const {
      isFreeTransfer, isLoading, dropDetails, showContent, showModal, mode, transferToken,
    } = this.state;
    return (
      <div>
        <SimpleModal isOpen={showModal} onBeforeClose={() => this.setState({ showModal: false })}>
          {mode === 'confirmTransfer'
            && (
            <ConfirmTransfer
                  isFree={isFreeTransfer}
                  nftToken={transferToken}
                  onConfirm={(token, isFree) => this.transferNFT(token, isFree)}
                  onCancel={(token) => this.cancelTransfer(token)} />
            )}
        </SimpleModal>
        {isLoading
          ? (
            <div>
              <FullPageLoader />
            </div>
          )
          : (
            <div>
              <ToastContainer
                position="top-right"
                autoClose={5000}
                pauseOnHover
                theme="light" />
              <TopNav dropDetails={dropDetails} />
              {showContent && !showModal
                && <Outlet />}
              <Footer />
              <CookieConsent
                cookieName={process.env.CREATOR_SUBDOMAIN + 'CookieConsent'}>
                <a href="https://www.ethosnft.com">ethos</a>
                {' '}
                uses cookies to provide necessary website functionality,
                improve your experience and analyze our traffic. By using our website, you agree to&nbsp;
                <a target="_blank" href="https://www.ethosnft.com/privacy" rel="noreferrer">our privacy policy</a>
                {' '}
                and our cookies usage.
                
              </CookieConsent>
            </div>
          )}
      </div>
    );
  }
}

export default Container;
