import Cookies from 'universal-cookie';
import Ajax from './Ajax';

/**
* Utility helper for Account functions
*/

class Account {
  constructor() {
    this.cookieName = '__ethosid';
    this.accountInfo = null;
    this.apiUrl = process.env.SERVER_URL;
  }

  create(url, payload, token) {
    // this should create an account
    // and it should return a bearer token
    const that = this;
    return Ajax.post(url, payload, { Authorization: token ? 'Bearer ' + token : '' })
      .then((data) => {
        // PubSub.publish('account_updated');
        that.accountInfo = data;
        return data;
      })
      .catch((error) => {
        // that.clear();
        throw error;
      });
  }

  billing = (url, payload, token) => Ajax.post(url, payload, { Authorization: token ? 'Bearer ' + token : '' })
    .then((data) => data)
    .catch((error) => {
      throw error;
    })

  saveCardDetails = (url, payload, token) => Ajax.post(url, payload, { Authorization: token ? 'Bearer ' + token : '' })
    .then((data) => data)
    .catch((error) => {
      // that.clear();
      throw error;
    })

  resetPassword = (payload) => Ajax.post(this.apiUrl + 'account/forgotpass', payload, null)
    .then((data) => data)
    .catch((error) => {
      throw error;
    })

  recoverPassword = (payload, resetToken, bearerToken) => Ajax.post(this.apiUrl + 'account/resetpass/' + resetToken, payload, { Authorization: bearerToken ? 'Bearer ' + bearerToken : '' })
    .then((data) => data)
    .catch((error) => {
      throw error;
    })

  changePassword = (payload, token) => Ajax.post(this.apiUrl + 'account/updatepass', payload, { Authorization: token ? 'Bearer ' + token : '' })
    .then((data) => data)
    .catch((error) => {
      throw error;
    })

  verifyResetToken = (token) => Ajax.post(this.apiUrl + 'account/resetpassword/verify/' + token, null)
    .then((data) => data)
    .catch((error) => {
      throw error;
    })

  update(payload, token) {
    // this should create an account
    // and it should return a bearer token
    return Ajax.put(this.apiUrl + 'account/update', payload, { Authorization: token ? 'Bearer ' + token : '' })
      .then((data) => data)
      .catch((error) => {
        // that.clear();
        throw error;
      });
  }

  login = (payload) => Ajax.post(this.apiUrl + 'account/authenticate', payload)
    .then((data) => data)
    .catch((error) => {
      // that.clear();
      throw error;
    })

  mfaLogin = (payload) => Ajax.post(this.apiUrl + 'account/mfa', payload)
    .then((data) => data)
    .catch((error) => {
      // that.clear();
      throw error;
    })

  get(token, forceUpdate) {
    const that = this;
    if (this.accountInfo !== null && !forceUpdate) {
      return this.accountInfo;
    }
    return Ajax.get(this.apiUrl + 'account', token)
      .then((data) => {
        // that._accountInfo = data;
        // that._aid = that._accountInfo.aid;
        // localStorage.setItem(AID,that._aid);
        // PubSub.publish('account_updated');
        // return that._accountInfo;
        that.accountInfo = data;
        return data;
      })
      .catch((error) => {
        // that.clear();
        throw error;
      });
  }

  getCollection(token) {
    return Ajax.get(this.apiUrl + 'wallet/collection', token)
      .then((data) => {
        const dropArray = [];
        const newDropArray = [];
        let newDropArrayObj = {};
        data.forEach((item) => {
          if (!dropArray.includes(item.drop.dropId)) {
            dropArray.push(item.drop.dropId);
            newDropArrayObj = {
              dropId: item.drop.dropId,
              name: item.drop.name,
              nfts: [],
            };
            newDropArray.push(newDropArrayObj);
            newDropArrayObj = {};
          }
        });
        data.forEach((item) => {
          newDropArray.forEach((drop) => {
            if (drop.dropId === item.drop.dropId) {
              drop.nfts.push(item);
            }
          });
        });

        return newDropArray;
      })
      .catch((error) => {
        // that.clear();
        throw error;
      });
  }

  userSearch(token, searchTerm) {
    return Ajax.get(this.apiUrl + 'user/search/' + searchTerm, token)
      .then((data) => data)
      .catch((error) => {
        // that.clear();
        throw error;
      });
  }

  emailSearch(token, email) {
    // TODO: url encode email address
    return Ajax.post(this.apiUrl + 'account/emailexists?email=' + encodeURIComponent(email), null, { Authorization: token ? 'Bearer ' + token : '' })
      .then((data) => data)
      .catch((error) => {
        // that.clear();
        throw error;
      });
  }

  getPaymentMethod(token) {
    return Ajax.get(this.apiUrl + 'payment/method', token)
      .then((data) => data)
      .catch((error) => {
        // that.clear();
        throw error;
      });
  }

  isLoggedIn() {
    const cookies = new Cookies();
    if (cookies.get(this.cookieName)) {
      return true;
    }
    return false;
  }

  logout() {
    const cookies = new Cookies();
    cookies.remove(this.cookieName, { path: '/' });
    setTimeout(() => {
      window.location = '/';
    }, 200);
  }

  setLoginState(token) {
    const cookies = new Cookies();
    const currentDate = new Date();
    const expiryDate = new Date(currentDate.setHours(currentDate.getHours() + 1));
    cookies.set(this.cookieName, token || 'no token set', { path: '/', expires: expiryDate });
  }

  getToken() {
    const cookies = new Cookies();
    return cookies.get(this.cookieName);
  }

  addWallet = (payload, token) => Ajax.post(this.apiUrl + 'wallet/AddWallet', payload, { Authorization: token ? 'Bearer ' + token : '' })
    .then((data) => data)
    .catch((error) => {
      // that.clear();
      throw error;
    })

  generateFreeNft(token, dropId) {
    return Ajax.get(this.apiUrl + 'transfer/FreeNftToken?dropId=' + dropId, token)
      .then((data) => data)
      .catch((error) => {
        // that.clear();
        throw error;
      });
  }

  transferFreeNFt = (token, payload) => Ajax.post(this.apiUrl + 'transfer/FreeNftToken', payload, { Authorization: token ? 'Bearer ' + token : '' })
    .then((data) => data)
    .catch((error) => {
      throw error;
    })

  claimNft = (payload, token) => Ajax.post(this.apiUrl + 'wallet/claim', payload, { Authorization: token ? 'Bearer ' + token : '' })
    .then((data) => data)
    .catch((error) => {
      // that.clear();
      throw error;
    })

  transferNFt = (token, payload) => Ajax.post(this.apiUrl + 'transfer/verify', payload, token)
    .then((data) => data)
    .catch((error) => {
      throw error;
    })

  transferNFtNotification = (token, payload) => Ajax.post(this.apiUrl + 'transfer/notification', payload, { Authorization: token ? 'Bearer ' + token : '' })
    .then((data) => data)
    .catch((error) => {
      throw error;
    })

  cancelTransfer = (token, payload) => Ajax.post(this.apiUrl + 'transfer/cancel', payload, { Authorization: token ? 'Bearer ' + token : '' })
    .then((data) => data)
    .catch((error) => {
      throw error;
    })

  getTransferDetails(token, tokenCode) {
    return Ajax.get(this.apiUrl + 'transfer/verify?transferToken=' + tokenCode, token)
      .then((data) => data)
      .catch((error) => {
        // that.clear();
        throw error;
      });
  }
}

export default new Account();
